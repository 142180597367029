import React from 'react';
import {
  Flex,
  Box,
  Heading,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Tag,
  Table,
  Tbody,
  Tr,
  Td,
}            from '@chakra-ui/react';

import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import SEO                           from '@interness/web-core/src/components/modules/SEO/SEO';
import Wrapper                       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';

import Gallery         from '@interness/ecommerce-addon/src/components/Gallery/Gallery';
import Error           from '@interness/ecommerce-addon/src/components/Error/Error';
import PriceTag        from '@interness/ecommerce-addon/src/components/PriceTag';
import AddToCartButton from '@interness/ecommerce-addon/src/components/AddToCartButton/AddToCartButton';
import ShippingTime    from '@interness/ecommerce-addon/src/components/ShippingTime';
import Tags            from '@interness/ecommerce-addon/src/components/Tags/Tags';
import Availability    from '@interness/ecommerce-addon/src/components/Availability';
import Personalization from '@interness/ecommerce-addon/src/components/Personalization/Personalization';

import ProductRatings from '@interness/ecommerce-addon/src/components/ProductRatings/ProductRatings';
import RatingStars    from '@interness/ecommerce-addon/src/components/ProductRatings/RatingStars';

const ProductDetails = (props) => {
  const { currentProduct, loading, error, settings } = props;

  if (loading || !currentProduct.details) {
    return <SpinnerContainer><Spinner/></SpinnerContainer>
  }

  if (error) {
    return <Error>Produkt konnte nicht geladen werden.</Error>
  }

  if (!currentProduct.details) {
    return <p>Produkt nicht gefunden</p>
  }

  return (
    <>
      <SEO title={currentProduct.details.display_name}/>
      <Spacer height={30}/>
      <Wrapper>
        <Button variant="ghost" colorScheme="brand" onClick={() => window.history.back()}>Zurück</Button>
      </Wrapper>
      <Spacer height={30}/>
      <Flex flexWrap="wrap" justifyContent="space-between">
        <Box width={{ base: '100%', lg: '50%' }} p={16} bg ="gray.50">
          <Gallery images={currentProduct.details.images}/>
        </Box>
        <Box width={{ base: '100%', lg: '50%' }} p={16}>
          <Heading style={{ textTransform: 'uppercase' }} size="xl">{currentProduct.details.display_name}</Heading>
          {settings.allow_ratings && currentProduct.ratingsCount !== 0 && currentProduct.averageRating !== 0 &&
          <RatingStars rating={currentProduct.averageRating} count={currentProduct.ratingsCount} showTooltip/>}
          <Spacer height={30} />
          <Text color="brand.500" fontWeight="bold" fontSize="xl">
            <PriceTag price={currentProduct.priceBrutto}
                      priceRange={currentProduct.variantPriceRange}/>
          </Text>
          {currentProduct.details.description && <div>
            <Spacer height={30} />
            <Heading as='h2' size='md' mb={5}>Beschreibung</Heading>
            <Text dangerouslySetInnerHTML={{ __html: currentProduct.details.description }}/>
          </div>}
          <Spacer/>
          {currentProduct.priceBrutto !== 0 && <AddToCartButton/>}
          <Spacer/>
        </Box>
      </Flex>
      <Box bg="gray.50" p={10}>
        <Wrapper>
          <Tabs colorScheme="brand" align="center">
            <TabList flexWrap='wrap'>
              <Tab><Heading as="h3" size="sm">Informationen</Heading></Tab>
              {currentProduct.details.size_guide && <Tab><Heading as="h3" size="sm">Größentabelle</Heading></Tab>}
              {currentProduct.details.personalization &&
              <Tab><Heading as="h3" size="sm">Personalisierung</Heading></Tab>}
              {settings.allow_ratings && <Tab><Heading as="h3" size="sm">Bewertungen <Tag
                ml={2}>{currentProduct.ratingsCount}</Tag></Heading></Tab>}
            </TabList>
            <TabPanels>
              <TabPanel>
                <Table>
                  <Tbody>
                  {settings.show_stock && <Tr sx={{
                    display: 'flex',
                    flexWrap: 'wrap'
                  }}>
                    <Td><b>Verfügbarkeit</b></Td>
                    <Td><Availability/></Td>
                  </Tr>}
                  {/* Shipping Time */}
                  {currentProduct.stock >= 0 && <Tr sx={{
                    display: 'flex',
                    flexWrap: 'wrap'
                  }}>
                    <Td><b>Lieferzeit</b></Td>
                    <Td>
                      <ShippingTime/>
                    </Td>
                  </Tr>}
                  {currentProduct.details.reference_number && <Tr sx={{
                    display: 'flex',
                    flexWrap: 'wrap'
                  }}>
                    <Td><b>Referenznummer</b></Td>
                    <Td>{currentProduct.details.reference_number}{currentProduct.variantSelected ? `.${currentProduct.variantSelected.reference_number_suffix}` : null}</Td>
                  </Tr>}
                  {/* Manufacturer */}
                  {currentProduct.details.manufacturer && <Tr sx={{
                    display: 'flex',
                    flexWrap: 'wrap'
                  }}>
                    <Td><b>Hersteller</b></Td>
                    <Td>{currentProduct.details.manufacturer ? currentProduct.details.manufacturer.display_name : 'Hersteller unbekannt'}</Td>
                  </Tr>}
                  {/* Attributes */}
                  {currentProduct.details.attributes.map(attribute => {
                    return (
                      <Tr key={attribute.attribute_group.display_name} sx={{
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}>
                        <Td><b>{attribute.attribute_group.display_name}</b></Td>
                        <Td>{attribute.display_values}</Td>
                      </Tr>
                    )
                  })}
                  <Tr sx={{
                    display: 'flex',
                    flexWrap: 'wrap'
                  }}>
                    <Td><b>Schlagwörter</b></Td>
                    <Td><Tags tags={currentProduct.details.tags}/></Td>
                  </Tr>
                  </Tbody>
                </Table>
              </TabPanel>
              {currentProduct.details.size_guide && <TabPanel>
                <Heading as="h3" size="md">{currentProduct.details.size_guide.display_name}</Heading>
                <div dangerouslySetInnerHTML={{ __html: currentProduct.details.size_guide.table }}/>
              </TabPanel>}
              {currentProduct.details.personalization && <TabPanel>
                <Personalization disabled={currentProduct.priceBrutto !== 0 && !currentProduct.reorderPossible}/>
              </TabPanel>}
              {settings.allow_ratings && <TabPanel>
                <ProductRatings productId={currentProduct.details.id}/>
              </TabPanel>}
            </TabPanels>
          </Tabs >
          <Spacer/>
        </Wrapper>
      </Box>
    </>
  )
};

ProductDetails.propTypes = {};

export default ProductDetails;